import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import ThemeProvider from 'providers/ThemeProvider';
import StoreProvider from 'providers/StoreProvider';
import LoadingProvider from 'providers/LoadingProvider';
import ScrollToTop from 'components/ScrollToTop';

import 'config/i18n';

const Main = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider>
          <LoadingProvider>
            <StoreProvider>
              <ScrollToTop/>
              <App/>
            </StoreProvider>
          </LoadingProvider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main/>);

// if(rootElement.hasChildNodes()) hydrate(<Main />, rootElement);
// else render(<Main />, rootElement);



