import { Route, Routes } from "react-router-dom"

import Layout from 'components/Layout';
import Home from 'views/Home';
import NoMatch from 'views/NoMatch';
import CandidateDetails from 'views/CandidateDetails';
// import Register from 'views/Register';

const App = () => {
  return (
    <Routes>
      <Route element={<Layout/>}>
        <Route index element={<Home/>} />
        {/* <Route path={'register'} element={<Register/>}/> */}
        <Route path={'candidate/:kod'} element={<CandidateDetails/>} />
        <Route path={'candidate/:negeri/:kod'} element={<CandidateDetails/>} />
      </Route>
      <Route path={"*"} element={<NoMatch />} />
    </Routes>
  )
}

export default App
