import RHelmet from 'react-helmet';

const Helmet = ({
  children,
  title='Calon Keadilan | Ini Calon Kita | Kita Boleh',
  description='Kenali calon-calon Parti Keadilan Rakyat untuk Pilihan Raya Umum ke-15. #KitaBoleh',
  url='https://calonkeadilan.org/',
  image='https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/calonkeadilan/webp/main-poster.webp'
}) => {
  return (
    <RHelmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Facebook */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* Twitter */}
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </RHelmet>
  );
}

export default Helmet;