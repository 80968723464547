const ml = {
  t: {
    // home page
    masthead: 'INI CALON KITA',
    mastheadDesc: 'Hantar permohonan secara atas talian untuk menjadi calon Parti Keadilan Rakyat bagi kerusi Parlimen atau Dewan Undangan Negeri (DUN) bagi menghadapi Pilihanraya Umum (PRU) ke-15',
    mastheadClose: 'Proses permohonan menjadi calon Parti Keadilan Rakyat telah ditutup.\n\nUntuk sebarang pertanyaan lanjut boleh disalurkan menerusi emel di calon@calonkeadilan.org.\n\nTerima kasih.',
    mastheadCalon: 'Lihat profil calon-calon Parti Keadilan Rakyat bagi kawasan anda di bawah.',
    conditions: 'Syarat-syarat pelantikan',
    malaysian: 'Warganegara Malaysia',
    pkrMember: 'Anggota Parti Keadilan Rakyat',
    academic: 'Kelulusan akademik dari institusi yang sah & diiktiraf',
    notBankrupt: 'Tidak muflis',
    notCriminal: 'Tiada rekod jenayah',
    healthy: 'Sihat mental & fizikal',
    notDrugAddict: 'Bebas daripada penyalahgunaan dadah',
    noDebt: 'Tidak berhutang dengan parti',
    extraCondition: 'Syarat tambahan lain selain dari di atas yang ditetapkan dari semasa ke semasa oleh Parti Keadilan Rakyat',
    tncDisclaimer: 'Saya mengesahkan bahawa saya mematuhi setiap syarat yang ditetapkan dan tertakluk kepada peraturan-peraturan yang berkaitan',
    registerCandidate: 'Daftar Calon',

    // register page
    formTitle: 'BORANG ATAS TALIAN PERMOHONAN CALON PRU KE-15',
    parlimenDun: 'Parlimen/DUN',
    parlimen: 'Parlimen',
    dun: 'Dewan Undangan Negeri (DUN)',
    dunShort: 'DUN',
    state: 'Negeri',
    applicantInfo: 'Maklumat pemohon',
    fullName: 'Nama Penuh',
    icNumber: 'No. Kad Pengenalan',
    icNoPassport: 'No. K/P / No. Passport',
    dob: 'Tarikh Lahir',
    pob: 'Tempat Lahir',
    age: 'Umur',
    yearsOld: 'tahun',
    race: 'Bangsa',
    religion: 'Agama',
    address: 'Alamat Tetap',
    billingAddress: 'Alamat Surat Menyurat',
    phoneNumber: 'No. Telefon',
    email: 'Emel',
    facebook: 'Facebook',
    membershipNumber: 'No. Anggota',
    joinDate: 'Tarikh Masuk',
    position: 'Jawatan',
    branch: 'Cabang',
    education: 'Pendidikan',
    lowToHigh: 'Tertinggi ke terendah',
    year: 'Tahun',
    institution: 'Institusi & Alamat',
    grade: 'Gred Pencapaian',
    career: 'Kerjaya',
    latestToEarliest: 'Terkini ke terdahulu',
    employer: 'Majikan & Alamat',
    politicalInvolvement: 'Penglibatan Politik',
    partyName: 'Nama Parti Politik & Alamat',
    ngoInvolvement: 'Penglibatan Pertubuhan (NGO)',
    orgName: 'Nama Pertubuhan & Alamat',
    page: 'MUKA <bold>{{ page }}</bold>/{{ totalPages }}',
    next: 'Seterusnya',
    back: 'Kembali',
    incomeInfo: 'Maklumat pendapatan',
    monthlyIncome: 'Pendapatan Bulanan (RM)',
    salary: 'Gaji',
    others: 'Lain-lain',
    total: 'Jumlah',
    bankInfo: 'Maklumat Perbankan Pemohon',
    bankName: 'Nama Bank',
    accountNumber: 'No. Akaun',
    dependents: 'Tanggungan Kewangan Bulanan dengan Institusi Kewangan',
    monetaryInstitution: 'Nama Institusi Kewangan',
    loanReason: 'Tujuan Pinjaman',
    period: 'Tempoh Akhir (tahun)',
    monthly: 'Bulanan (RM)',
    health: 'Kesihatan',
    yes: 'Ya',
    no: 'Tidak',
    isSick: 'Saya menghidap masalah kesihatan yang serius',
    ifYesState: 'Jika jawapan adalah Ya, sila nyatakan:',
    familyInfo: 'Maklumat Keluarga',
    single: 'Bujang',
    spouseName: 'Nama Suami/Isteri',
    occupation: 'Pekerjaan',
    employerAddress: 'Alamat Majikan',
    childrenInfo: 'Maklumat Anak',
    oldToYoung: 'Paling tua ke muda',
    childName: 'Nama Anak',
    school: 'Sekolah/Pekerjaan',
    qualification: 'Kelayakan Calon',
    state3Strength: 'Nyatakan 3 kekuatan/kelebihan faktor yang melayakkan anda menjadi calon',
    confession: 'Pengakuan Pemohon',
    isBankrupt: 'Saya diisytihar muflis',
    includeCert: 'Sertakan "Sijil Status Insolvensi" daripada Jabatan Insolvensi',
    chooseFile: 'Pilih fail',
    isCriminal: 'Saya pernah mempunyai rekod jenayah',
    isDrugAddict: 'Saya pernah terlibat dengan penyalahgunaan dadah',
    isHealthy: 'Saya sihat dari segi aspek mental & fizikal untuk melaksanakan tugas yang akan diamanahkan',
    isStatementTrue: 'Semua keterangan saya di dalam borang ini adalah benar & saya bersedia untuk diambil tindakan oleh Parti Keadilan Rakyat jika terdapat maklumat yang tidak benar.',
    submit: 'Hantar',
    thankYou: 'Terima kasih',
    applicationAccepted: 'Permohonan anda telah diterima',
    acceptDate: 'Tarikh Terima',

    shareOn: 'Kongsi di',
    assetDeclaration: 'Dokumen Pengisytiharan Harta',
    willUpdate: 'Akan dikemaskini'
  },
  error: {
    fullName: 'Sila isi nama penuh anda'
  }
};

export default ml;