import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import 'stylesheets/css/loading.css';

const ProgressiveImage = ({ placeholderSrc, src, errorSrc, alt='', ...rest }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImgSrc(src);
    img.onerror = () => setImgSrc(errorSrc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const customClass = placeholderSrc && imgSrc === placeholderSrc ? 'image-loading' : 'image-loaded';

  return (
    <Box
      component={'img'}
      src={imgSrc}
      alt={alt}
      onError={(e) => {
        e.target.src = errorSrc;
        e.onerror = null;
      }}
      className={`${customClass}`}
      {...rest}
    />
  );
};
export default ProgressiveImage;