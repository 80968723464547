import { Box, Dialog } from '@mui/material';
import useWindowSize from 'hooks/useWindowSize';
import { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewModal = ({ open=false, previewUrl=null, errorMessage='Failed to load PDF file', ...rest }) => {
  const [numPages, setNumPages] = useState(null);

  const onLoadPdfSuccess = ({ numPages, ...args }) => {
    setNumPages(numPages);
  }

  const { width } = useWindowSize();
  
  return (
    <Dialog
      open={open}
      maxWidth={'xl'}
      {...rest}
    >
      <Box
        width={1}
        sx={{
          maxHeight: '90vh',
          p: !numPages ? 3 : 0
        }}
      >
        <Document file={previewUrl} error={errorMessage} onLoadSuccess={(e) => onLoadPdfSuccess(e)}>
          {[...Array(numPages).keys()].map((p) => (
            <Box key={p} sx={{borderBottom: p < numPages-1 ? '1px solid black' : 'none'}}>
              <Page pageNumber={p+1} width={width * 0.8}/>
            </Box>
          ))}
        </Document>
      </Box>
    </Dialog>
  );
}

export default PdfViewModal;