const objectToGetParams = (object) => {
  const params = Object.keys(object).filter(key => !!object[key]);

  if (!params.length) return '';
  return '?' + params.map(key => `${key}=${encodeURIComponent(object[key])}`).join('&');
}

const generators = {
  facebook: params => {
    const { url, text = '', hashtags = [] } = params;
    return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
      u: url,
      quote: text + '\n\n' || '',
      hashtag: hashtags.map(h => `#${h}`)
    })}`;
  },
  twitter: params => {
    const { url, text = '', via = '', hashtags = [] } = params;
    return `https://twitter.com/share${objectToGetParams({
      url,
      text: text + '\n\n',
      via,
      hashtags: hashtags.join(',')
    })}`;
  }
};

const isValidNetwork = (key) => {
  return generators.hasOwnProperty(key) ? true : false;
}

const getNetworkLink = (params) => {
  const { network, ...rest } = params;
  const { url } = rest;

  const key = network.toLowerCase();

  if (!key) throw new Error(`Please specify the 'network' prop`);

  if (!isValidNetwork(key)) throw new Error(`The 'network' prop's value is invalid`);

  if (!url) throw new Error(`Please specify the 'url' prop`);

  return generators[key](rest);
}

const openShareWindow = (url, width = 650, height = 450) => {
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2;
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2;

  const config = {
    height,
    width,
    left,
    top,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  };

  const shareDialog = window.open(
    url,
    'share',
    Object.keys(config).map(key => `${key}=${config[key]}`).join(',')
  );

  return shareDialog;
}

export const shareOnSocialMedia = (args) => {
  const { url, network, text, link, media, hashtags } = args;
  const networkLink = link ? link : getNetworkLink({ network, url, text, media, hashtags });
  openShareWindow(networkLink);
};
