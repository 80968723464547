import { Box, Grid, useMediaQuery } from '@mui/material';
import { URLS } from 'helper';
import { Outlet, useNavigate } from 'react-router-dom';

const Layout = () => {
	const smDown = useMediaQuery('(max-width: 600px)');
	const navigate = useNavigate();

  return (
		<Grid container sx={{position: 'relative', width: '100vw'}}>
			<Grid
				item
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					pt: 5,
					px: 6,
					display: smDown ? 'none' : 'block'
				}}
			>
				<Box
					sx={{
						width: 360,
						height: 48,
						background: `transparent url(${URLS.LOGO})`,
						backgroundSize: 'contain',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'top center',
						'&:hover': {
							cursor: 'pointer'
						}
					}}
					onClick={() => navigate('/')}
				/>
			</Grid>

			<Outlet/>
		</Grid>
  );
}

export default Layout;