import { format } from 'date-fns';

const ENDPOINT = 'https://itcwu78qt3.execute-api.ap-southeast-1.amazonaws.com/dev';

export const sendApplication = async (values) => {
  const data = {
    "a_parlimen_dun": values.category,
    ...values.category === 'parlimen' && {"b_parlimen_dun": values.parliament},
    ...values.category === 'dun' && {"b_parlimen_dun": values.dun},
    "b_negeri": values.state,
    "b_nama_penuh": values.fullName,
    "b_no_kp": values.icNumber,
    "b_tarikh_lahir": values.dob,
    "b_tempat_lahir": values.pob,
    "b_umur": `${values.age} tahun`,
    "b_bangsa": values.race,
    "b_agama": values.religion,
    "b_alamat_tetap": values.address,
    "b_alamat_surat": values.billingAddress,
    "b_no_telefon": values.phoneNumber,
    "b_email": values.email,
    "b_facebook": values.facebook,
    "b_no_anggota": values.membershipNumber,
    "b_tarikh_masuk": values.joinDate,
    "b_jawatan": values.position,
    "b_cabang": values.branch,
    "c_pendidikan": values.educations.map(e => ({
      "c_tahun": e.year,
      "c_institusi": e.institution,
      "c_gred": e.grade
    })),
    "d_kerjaya": values.careers.map(c => ({
      "d_tahun": c.year,
      "d_jawatan": c.position,
      "d_majikan_alamat": c.employer
    })),
    "e_politik": values.politics.map(p => ({
      "e_tahun": p.year,
      "e_jawatan": p.position,
      "e_parti_politik": p.party
    })),
    "f_ngo": values.ngos.map(n => ({
      "f_tahun": n.year,
      "f_jawatan": n.position,
      "f_nama_pertubuhan": n.organisation
    })),
    "g_gaji": `RM ${values.monthlySalary}`,
    "g_lain_lain_pendapatan": `RM ${values.otherSalary}`,
    "g_jumlah_pendapatan": `RM ${values.totalSalary}`,
    "g_nama_bank": values.bankName,
    "g_no_akaun": values.bankAccNumber,
    "g_pinjaman": values.dependents.map(d => ({
      "g_institusi": d.institution,
      "g_tujuan_pinjaman": d.reason,
      "g_tempoh_akhir": d.period,
      "g_bulanan": `RM ${d.amount}`
    })),
    ...(values.isSick && values.healthProblems.length) && {
      "h_kesihatan": values.healthProblems.filter(p => p).map((p) => ({
        "h_masalah_kesihatan": p
      }))
    },
    "i_nama_pasangan": values.spouseName,
    "i_no_kp": values.spouseIcNumber,
    "i_tarikh_lahir": values.spouseDob,
    "i_tempat_lahir": values.spousePob,
    "i_umur": `${values.spouseAge} tahun`,
    "i_bangsa": values.spouseRace,
    "i_agama": values.spouseReligion,
    "i_pekerjaan": values.spouseOccupation,
    "i_alamat_majikan": values.spouseEmployerAddress,
    "i_no_tel": values.spousePhoneNumber,
    "i_email": values.spouseEmail,
    "i_anak": values.children.map(c => ({
      "i_nama_anak": c.name,
      "i_sekolah_pekerjaan": c.school,
      "i_umur": c.age
    })),
    "j_kelayakan": values.qualifications.map(q => ({
      "j_kelayakan_calon": q
    })),
    "k_muflis": values.isBankrupt,
    "k_sijil_insolvensi": values.insolvencyStatusCert ? 'Ya' : 'Tidak',
    "k_jenayah": values.isCriminal,
    "k_dadah": values.isDrugAddict,
    "k_kesihatan": values.isHealthy,
    "k_nama_pemohon": values.fullName,
    "k_no_kp": values.icNumber,
    "k_tandatangan": 'Ya',
    "k_tarikh": format(new Date(), 'dd/MM/yyyy')
  };

  const res = await fetch(ENDPOINT + '/api/calon', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    if (res.status !== 200 & res.status !== 201) {
      throw new Error(res.text() || 'Failed');
    }
    return res.json();
  })
  .then((resData) => {
    return resData;
  })
  .catch((err) => {
    console.log(err);
    throw new Error(err);
  });

  return res;
}

export const uploadFile = async (file) => {
  const data = new FormData();
  data.append('file', file);
  
  const res = await fetch(ENDPOINT + '/api/calon/upload-doc', {
    method: 'POST',
    body: data
  })
  .then((res) => {
    if (res.status !== 200 & res.status !== 201) {
      throw new Error(res.text() || 'Failed');
    }
    return res.json();
  })
  .then((resData) => {
    return resData;
  })
  .catch((err) => {
    console.log(err);
    throw new Error(err);
  });
  return res;
}

export const getCandidatesByNegeri = async (state) => {
  const res = await fetch(ENDPOINT + '/api/calon/view-negeri', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "negeri": state 
    })
  })
  .then((res) => {
    if (res.status !== 200 & res.status !== 201) {
      throw new Error(res.text() || 'Failed');
    }
    return res.json();
  })
  .then((resData) => {
    return resData;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });
  return res;
}

export const getCandidateDetails = async (code, state, type='parlimen') => {
  const res = await fetch(ENDPOINT + `/api/calon/view-${type}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      [`kod_${type}`]: code,
      ...state && { negeri: state }
    })
  })
  .then((res) => {
    if (res.status !== 200 & res.status !== 201) {
      throw new Error(res.text() || 'Failed');
    }
    return res.json();
  })
  .then((resData) => {
    return resData;
  })
  .catch((err) => {
    console.log(err);
    return err;
  });
  return res;
}