import { Grid, IconButton, Typography } from '@mui/material';
import { capitalizeString, getCandidateAssetDeclarationFile, getCandidateBannerFile, URLS } from 'helper';
import useStore from 'hooks/useStore';
import useTranslator from 'hooks/useTranslator';
import { Facebook, Twitter } from '@mui/icons-material';
import Button from 'components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { shareOnSocialMedia } from 'helper/social';
import { useEffect, useState } from 'react';
import { getCandidateDetails } from 'api';
import useLoading from 'hooks/useLoading';
import ProgressiveImage from 'components/ProgressiveImage';
import Helmet from 'components/Helmet';
import PdfViewModal from 'components/PdfViewModal';

const styles = {
  header: (theme) => ({
    width: '100vw',
    height: '12vh',
    minHeight: 129,
    background: `transparent url(${URLS.BANNER})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }),
  body: (theme) => ({
    width: '100%',
    px: '16vw',
    py: 5,
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    },
    [theme.breakpoints.down('sm')]: {
      px: '7vw',
      py: 3
    }
  }),
  banner: {
    width: '100%'
  },
  details: (theme) => ({
    px: 10,
    py: 3,
    [theme.breakpoints.down('md')]: {
      px: 5
    },
    [theme.breakpoints.down('sm')]: {
      px: 1,
      py: 3
    }
  }),
  rightDiv: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  }),
  link: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  }
};

const CandidateDetails = () => {
  const { selectedCandidate, setSelectedCandidate } = useStore();
  const { t } = useTranslator();
  const navigate = useNavigate();
  const location = useLocation();

  const { loading, setLoading } = useLoading();

  const areaCode = selectedCandidate.kod_parlimen || selectedCandidate.kod_dun;

  const shareSocMed = (network) => {
    shareOnSocialMedia({
      url: window.location.href,
      network,
      text: `${selectedCandidate.b_pemohon[0]?.nama_penuh} | ${areaCode} - ${selectedCandidate.parlimen || selectedCandidate.dun}`,
      hashtags: [
        'KitaBoleh',
        'AyuhMalaysia',
        'KEADILAN',
        ...selectedCandidate.kod_parlimen ? [selectedCandidate.kod_parlimen] : [],
        ...selectedCandidate.kod_dun ? [selectedCandidate.kod_dun] : [],
        'PRU15'
      ]
    });
  }

  const fetchCandidate = async (code, state, type) => {
    setLoading(true);
    const res = await getCandidateDetails(code.toLowerCase(), state, type);
    if(res.status === 200 && res.data) {
      setSelectedCandidate(res.data[0] || {});
    }
    else {
      setSelectedCandidate({});
    }
    setLoading(false);
  }

  useEffect(() => {
    if(selectedCandidate.init === null) {
      const path = location.pathname.split('/');
      const code = path.pop();
      const codeType = code.toLowerCase()[0];
      const state = path.pop();
      fetchCandidate(code, capitalizeString(state.split('_').join(' ')), codeType === 'p' ? 'parlimen' : codeType === 'n' ? 'dun' : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [assetDecOpen, setAssetDecOpen] = useState(false);
  const [assetDecUrl, setAssetDecUrl] = useState(null);

  return (
    <>
      <Helmet
        title={areaCode ? `${areaCode} - ${selectedCandidate.parlimen || selectedCandidate.dun}` : null}
        description={areaCode ? `${selectedCandidate.b_pemohon ? selectedCandidate.b_pemohon[0]?.nama_penuh : ''} #KitaBoleh` : null}
        url={selectedCandidate.parlimen ? `https://calonkeadilan.org/candidate/${selectedCandidate.kod_parlimen}` : selectedCandidate.dun ? `https://calonkeadilan.org/candidate/${selectedCandidate.negeri?.toLowerCase().split(' ').join('_')}/${selectedCandidate.kod_dun}` : null}
        image={getCandidateBannerFile(selectedCandidate.negeri, areaCode)}
      />

      {loading ? null 
      :
      <Grid container>
        <Grid item xs={12} sx={styles.header}/>
        <Grid container item xs={12} sx={styles.body}>
          <Grid item xs={12}>
            <ProgressiveImage
              src={getCandidateBannerFile(selectedCandidate.negeri, areaCode)}
              placeholderSrc={getCandidateBannerFile('placeholder')}
              errorSrc={getCandidateBannerFile('default')}
              sx={styles.banner}
            />
          </Grid>
          <Grid container item xs={12} sx={styles.details}>

            {/* disable temporarily */}
            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
              <Typography color={'darkGrey'} variant={'caption'}>{t('t:shareOn')}</Typography>
              <IconButton disableRipple sx={{p: 0, ml: 1}} onClick={() => shareSocMed('facebook')}>
                <Facebook sx={{color: '#2060AC'}}/>
              </IconButton>
              <IconButton disableRipple sx={{p: 0, ml: 1}} onClick={() => shareSocMed('twitter')}>
                <Twitter sx={{color: '#429AD7'}}/>
              </IconButton>
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={12} sx={{py: 8}}>
                <Grid item xs={12} sm={7}>
                  <Typography textTransform={'uppercase'} color={'black'} variant={'titleSmall'}>
                    {selectedCandidate.b_pemohon ? selectedCandidate.b_pemohon[0]?.nama_penuh : 'Calon'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5} sx={styles.rightDiv}>
                  <Typography textTransform={'uppercase'} color={'black'} variant={'body1'}>
                    {selectedCandidate.b_pemohon ? selectedCandidate.b_pemohon[0]?.umur : ''}
                  </Typography>
                </Grid>
              </Grid>

              { selectedCandidate.c_pendidikan?.length ?
              <Grid item xs={12} sx={{pt: 4}}>
                <Typography textTransform={'uppercase'} color={'black'} variant={'caption'} fontFamily={'AGE XBold'}>
                  {t('t:education')}
                </Typography>
                <Grid container sx={{py: 2}}>
                  { selectedCandidate.c_pendidikan.map((p,i) => (
                    <Grid key={i} container item xs={12} sx={{py: 1}}>
                      <Grid item xs={12} sm={8}>
                        <Typography color={'black'} variant={'body2'}>{p.institusi}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} sx={styles.rightDiv}>
                        <Typography color={'black'} variant={'body2'} textTransform={'capitalize'}>{p.tahun?.toString()?.toLowerCase()}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid> : null }

              { selectedCandidate.d_kerjaya?.length ?
              <Grid item xs={12} sx={{pt: 4}}>
                <Typography textTransform={'uppercase'} color={'black'} variant={'caption'} fontFamily={'AGE XBold'}>
                  {t('t:career')}
                </Typography>
                <Grid container sx={{py: 2}}>
                  { selectedCandidate.d_kerjaya.map((k,i) => (
                    <Grid key={i} container item xs={12} sx={{py: 1}}>
                      <Grid item xs={12} sm={8}>
                        <Typography color={'black'} variant={'body2'}>{`${k.jawatan}${k.majikan_alamat ? ', '+k.majikan_alamat : ''}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} sx={styles.rightDiv}>
                        <Typography color={'black'} variant={'body2'} textTransform={'capitalize'}>{k.tahun?.toString()?.toLowerCase()}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid> : null }

              { selectedCandidate.e_politik?.length ?
              <Grid item xs={12} sx={{pt: 4}}>
                <Typography textTransform={'uppercase'} color={'black'} variant={'caption'} fontFamily={'AGE XBold'}>
                  {t('t:politicalInvolvement')}
                </Typography>
                <Grid container sx={{py: 2}}>
                  { selectedCandidate.e_politik.map((p,i) => (
                    <Grid key={i} container item xs={12} sx={{py: 1}}>
                      <Grid item xs={12} sm={8}>
                        <Typography color={'black'} variant={'body2'}>{`${p.jawatan}${p.parti_politik ? ', '+p.parti_politik : ''}`}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} sx={styles.rightDiv}>
                        <Typography color={'black'} variant={'body2'} textTransform={'capitalize'}>{p.tahun?.toString()?.toLowerCase()}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid> : null }
            </Grid>

            <Grid item xs={12} sx={{pt: 10}}>
              <Typography 
                color={'blue'}
                sx={styles.link}
                variant={'body2'}
                onClick={() => {
                  setAssetDecOpen(true);
                  setAssetDecUrl(getCandidateAssetDeclarationFile(selectedCandidate.negeri, areaCode));
                }}
              >
                {t('t:assetDeclaration')}
              </Typography>
            </Grid>

            <PdfViewModal
              open={assetDecOpen}
              previewUrl={assetDecUrl}
              onClose={() => {
                setAssetDecOpen(false);
                setTimeout(() => {
                  setAssetDecUrl(null);
                }, 750);
              }}
              errorMessage={t('t:willUpdate')}
            />

            <Grid item xs={12} sx={{pt: 10}}>
              <Button
                transparent
                label={t('t:back')}
                leftIcon={'chevron_left'}
                sx={{
                  px: 0
                }}
                labelProps={{
                  color: 'darkGrey',
                  variant: 'caption'
                }}
                leftIconProps={{
                  sx: {
                    color: 'darkGrey'
                  }
                }}
                onClick={() => navigate('/')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      }
    </>
  );
}

export default CandidateDetails;