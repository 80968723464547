import StoreContext from 'contexts/StoreContext';
import { useState } from 'react';

const StoreProvider = ({ children }) => {
  const [state, setState] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState({init: null});
  const [parlimenCandidates, setParlimenCandidates] = useState([]);
  const [dunCandidates, setDunCandidates] = useState([]);

  return (
    <StoreContext.Provider 
      value={{
        state,
        setState,
        selectedCandidate,
        setSelectedCandidate,
        parlimenCandidates,
        setParlimenCandidates,
        dunCandidates,
        setDunCandidates
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export default StoreProvider;