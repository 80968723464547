const variants = {
	masthead: {
		fontSize: 90,
		fontFamily: 'AGE XBold'
	},
	mastheadSub: {
		fontSize: 44,
		fontFamily: 'AGE'
	},
	footerText: {
		fontSize: 40,
		fontFamily: 'AGE XBold'
	},
	title: {
		fontSize: 35,
		fontFamily: 'AGE XBold'
	},
	titleSmall: {
		fontSize: 25,
		fontFamily: 'AGE XBold'
	},
	body1: {
		fontSize: 23,
		fontFamily: 'AGE'
	},
	body2: {
		fontSize: 21,
		fontFamily: 'AGE'
	},
	subtitle: {
		fontSize: 18,
		fontFamily: 'AGE'
	},
	caption: {
		fontSize: 15,
		fontFamily: 'AGE'
	},
	button: {
		fontSize: 20,
		fontFamily: 'AGE XBold'
	}
};

const fontSizes = {
	...Object.entries(variants).reduce((acc, [key, value]) => {
		return {
			...acc, 
			[key]: {
				...value,
				lineHeight: 1.5,
				color: 'white'
			}
		}
	}, {})
};

const typography = {
	fontFamily: ['"AGE"', '"Roboto"', '"Arial"', 'sans-serif'].join(', '),
	...fontSizes
};

export default typography;