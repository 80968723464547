import { Box } from '@mui/material';

const NoMatch = () => {
  return (
    <Box>
      404: Not Found
    </Box>
  );
}

export default NoMatch;