import { useState } from 'react';

import LoadingContext from 'contexts/LoadingContext';
import { Box, Dialog } from '@mui/material';

import 'stylesheets/css/loading.css';
import { URLS } from 'helper';

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider 
      value={{
        loading,
        setLoading
      }}
    >
      {children}
      <Dialog
        open={loading}
        fullScreen
        disableEscapeKeyDown
        onClose={(e, reason) => {
          if (reason === 'backdropClick') return;
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(1,1,1,0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <Box component={'div'} className={'loading-animation'}>
				  <Box
            component={'img'}
            src={URLS.PKR}
            alt={''}
          />
			  </Box>
      </Dialog>
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
