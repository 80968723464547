import { differenceInYears, isDate, parse } from 'date-fns';
import { object, string, array, date, mixed } from 'yup';

export const candidateFormInitialValues = {
  // A - DUN or Parlimen
  category: '',

  // B - Maklumat Pemohon
  dun: '',
  parliament: '',
  state: '',
  fullName: '',
  icNumber: '',
  dob: '',
  pob: '',
  age: '',
  race: '',
  religion: '',
  address: '',
  billingAddress: '',
  phoneNumber: '',
  email: '',
  facebook: '',
  membershipNumber: '',
  joinDate: '',
  position: '',
  branch: '',

  // C - Pendidikan
  educations: [{
    year: '',
    institution: '',
    grade: ''
  }],

  // D - Kerjaya
  careers: [{
    year: '',
    position: '',
    employer: ''
  }],

  // E - Penglibatan Politik
  politics: [],

  // F - Penglibatan Pertubuhan (NGO)
  ngos: [],

  // G - Maklumat Pendapatan
  monthlySalary: '',
  otherSalary: '',
  totalSalary: '',
  bankName: '',
  bankAccNumber: '',
  dependents: [],

  // H - Kesihatan
  isSick: '',
  healthProblems: ['', '', ''],

  // I - Maklumat Keluarga
  single: false,
  spouseName: '',
  spouseIcNumber: '',
  spouseDob: '',
  spousePob: '',
  spouseAge: '',
  spouseRace: '',
  spouseReligion: '',
  spouseOccupation: '',
  spouseEmployerAddress: '',
  spousePhoneNumber: '',
  spouseEmail: '',
  children: [],

  // J - Kelayakan Calon
  qualifications: ['', '', ''],

  // K - Pengakuan Pemohon
  isBankrupt: '',
  insolvencyStatusCert: null,
  isCriminal: '',
  isDrugAddict: '',
  isHealthy: '',

  isStatementTrue: false,

  signature: null,
  date: new Date()
};

// export const candidateFormInitialValues = {
//   // A - DUN or Parlimen
//   category: 'dun',

//   // B - Maklumat Pemohon
//   dun: 'Rantau',
//   parliament: '',
//   state: 'Negeri Sembilan',
//   fullName: 'Hanafi Othman',
//   icNumber: '951012-14-1111',
//   dob: '12/10/1995',
//   pob: 'KL',
//   age: '26',
//   race: 'Melayu',
//   religion: 'Islam',
//   address: 'Test 1',
//   billingAddress: 'Test 2',
//   phoneNumber: '010-8201210',
//   email: 'afiman95@gmail.com',
//   facebook: 'hanafiothman',
//   membershipNumber: 'ADIL-1234',
//   joinDate: '12/08/2009',
//   position: 'Ahli Aktif',
//   branch: 'Sungai Besi',

//   // C - Pendidikan
//   educations: [{
//     year: '2009',
//     institution: 'Test inst',
//     grade: 'Cemerlang'
//   }],

//   // D - Kerjaya
//   careers: [{
//     year: '2019',
//     position: 'Software Engineer',
//     employer: 'INVOKE, Sg. Besi'
//   }],

//   // E - Penglibatan Politik
//   politics: [{
//     year: '2008',
//     position: 'Ahli Aktif',
//     party: 'UMNO'
//   }],

//   // F - Penglibatan Pertubuhan (NGO)
//   ngos: [{
//     year: '2008',
//     position: 'Ahli Aktif',
//     organisation: 'IKRAM'
//   }],

//   // G - Maklumat Pendapatan
//   monthlySalary: '3000',
//   otherSalary: '1500',
//   totalSalary: '',
//   bankName: 'Maybank',
//   bankAccNumber: '123456',
//   dependents: [{
//     institution: 'Maybank', reason: 'Loan kereta', period: '7', amount: '1200'
//   }],

//   // H - Kesihatan
//   isSick: 'Ya',
//   healthProblems: ['test 1', 'test 2', ''],

//   // I - Maklumat Keluarga
//   single: false,
//   spouseName: 'Test spouse',
//   spouseIcNumber: '960202-05-1122',
//   spouseDob: '02/02/1996',
//   spousePob: 'KL',
//   spouseAge: '26',
//   spouseRace: 'Melayu',
//   spouseReligion: 'Islam',
//   spouseOccupation: 'Suri rumah',
//   spouseEmployerAddress: '-',
//   spousePhoneNumber: '010-8288282',
//   spouseEmail: 'test@email.com',
//   children: [{
//     name: 'Anak 1',
//     school: 'Sekolah test',
//     age: '6'
//   },
//   {
//     name: 'Anak 2',
//     school: 'Sekolah test 2',
//     age: '4'
//   }],

//   // J - Kelayakan Calon
//   qualifications: ['Berani', 'Kerana', 'Benar'],

//   // K - Pengakuan Pemohon
//   isBankrupt: '',
//   insolvencyStatusCert: null,
//   isCriminal: '',
//   isDrugAddict: '',
//   isHealthy: '',

//   isStatementTrue: false,

//   signature: null,
//   date: new Date()
// };

export const candidateFormValidator1 = object({
  // A - DUN or Parlimen
  category: string().required('Sila pilih Parlimen atau DUN'),

  // B - Maklumat Pemohon
  dun: string().when('category', {
    is: 'dun',
    then: string().required('Sila isi nama DUN anda'),
  }),
  parliament: string().when('category', {
    is: 'parlimen',
    then: string().required('Sila isi nama Parlimen anda'),
  }),
  state: string().required('Sila pilih negeri anda'),
  fullName: string().required('Sila isi nama penuh anda'),
  icNumber: string()
    .min(14, 'No. kad pengenalan tidak sah')
    .max(14, 'No. kad pengenalan tidak sah')
    .required(`Sila isi no. kad pengenalan anda`),
  dob: date()
    .nullable()
    .typeError('Tarikh lahir tidak sah')
    .transform((_, ov) => {
      const parsedDate = isDate(ov) ? ov : parse(ov, 'dd/MM/yyyy', new Date());
      return parsedDate;
    })
    .max(new Date(), 'Tarikh lahir tidak sah')
    .required('Sila isi tarikh lahir anda'),
  pob: string().required('Sila isi tempat lahir anda'),
  age: string().required('Sila isi umur anda'),
  race: string().required('Sila pilih bangsa anda'),
  religion: string().required('Sila pilih agama anda'),
  address: string().required('Sila isi alamat tetap anda'),
  billingAddress: string().required('Sila isi alamat surat-menyurat anda'),
  phoneNumber: string().required('Sila isi no. telefon anda'),
  email: string().email('Emel tidak sah').required('Sila isi emel anda'),
  facebook: string().required('Sila isi Facebook anda'),
  membershipNumber: string().required('Sila isi no. anggota anda'),
  joinDate: date()
    .nullable()
    .typeError('Tarikh masuk tidak sah')
    .transform((_, ov) => {
      const parsedDate = isDate(ov) ? ov : parse(ov, 'dd/MM/yyyy', new Date());
      return parsedDate;
    })
    .max(new Date(), 'Tarikh masuk tidak sah')
    .required('Sila isi tarikh masuk parti anda'),
  position: string().required('Sila isi jawatan anda dalam parti'),
  branch: string().required('Sila isi cabang parti anda'),

  // C - Pendidikan
  educations: array().of(
    object().shape({
      year: string().required('Sila isi tahun'),
      institution: string().required('Sila isi institusi'),
      grade: string().required('Sila isi gred')
    }))
    .required('Sila isi maklumat pendidikan anda'),

  // D - Kerjaya
  careers: array().of(
    object().shape({
      year: string().required('Sila isi tahun'),
      position: string().required('Sila isi jawatan'),
      employer: string().required('Sila isi majikan')
    }))
    .required('Sila isi maklumat kerjaya anda'),

  // E - Penglibatan Politik
  politics: array().of(
    object().shape({
      year: string().required('Sila isi tahun'),
      position: string().required('Sila isi jawatan'),
      party: string().required('Sila isi parti politik')
    })),
    // .required('Sila isi maklumat penglibatan politik anda'),

  // F - Penglibatan Pertubuhan (NGO)
  ngos: array().of(
    object().shape({
      year: string().required('Sila isi tahun'),
      position: string().required('Sila isi jawatan'),
      organisation: string().required('Sila isi pertubuhan')
    }))
    // .required('Sila isi maklumat penglibatan pertubuhan (NGO) anda')
});

export const candidateFormValidator2 = candidateFormValidator1.concat(
    object({
    // G - Maklumat Pendapatan
    monthlySalary: string().required('Sila isi pendapatan bulanan anda'),
    otherSalary: string(),
    bankName: string().required('Sila isi nama bank anda'),
    bankAccNumber: string().required('Sila isi no. akaun bank anda'),
    dependents: array().of(
      object().shape({
        institution: string().required('Sila isi institusi'),
        reason: string().required('Sila isi tujuan'),
        period: string().required('Sila isi tempoh akhir'),
        amount: string().required('Sila isi jumlah bulanan')
      })),

    // H - Kesihatan
    isSick: string().required('Sila jawab soalan ini'),
    healthProblems: array().when('isSick', {
      is: 'Ya',
      then: array().of(string()),
    }),

    // I - Maklumat Keluarga
    spouseName: string().when('single', {
      is: false,
      then: string().required('Sila isi nama suami/isteri'),
    }),
    spouseIcNumber: string()
      // .min(14, 'No. kad pengenalan tidak sah')
      // .max(14, 'No. kad pengenalan tidak sah')
      .when('single', {
        is: false,
        then: string().required('Sila isi no. kad pengenalan / no. passport pasangan'),
      }),
    spouseDob: date()
      .nullable()
      .typeError('Tarikh lahir tidak sah')
      .transform((_, ov) => {
        const parsedDate = isDate(ov) ? ov : parse(ov, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .max(new Date(), 'Tarikh lahir tidak sah')
      .when('single', {
        is: false,
        then: date().required('Sila isi tarikh lahir pasangan'),
      }),
    spousePob: string().when('single', {
      is: false,
      then: string().required('Sila isi tempat lahir pasangan'),
    }),
    spouseAge: string().when('single', {
      is: false,
      then: string().required('Sila isi umur pasangan'),
    }),
    spouseRace: string().when('single', {
      is: false,
      then: string().required('Sila isi bangsa pasangan'),
    }),
    spouseReligion: string().when('single', {
      is: false,
      then: string().required('Sila isi agama pasangan'),
    }),
    spouseOccupation: string().when('single', {
      is: false,
      then: string().required('Sila isi pekerjaan pasangan'),
    }),
    spouseEmployerAddress: string().when('single', {
      is: false,
      then: string().required('Sila isi alamat majikan pasangan'),
    }),
    spousePhoneNumber: string().when('single', {
      is: false,
      then: string().required('Sila isi no. telefon pasangan'),
    }),
    spouseEmail: string().email('Emel tidak sah').when('single', {
      is: false,
      then: string().required('Sila isi emel pasangan'),
    }),
    children: array().of(
      object().shape({
        name: string().required('Sila isi nama'),
        school: string().required('Sila isi sekolah/pekerjaan'),
        age: string().required('Sila isi umur')
      })),

    // J - Kelayakan Calon
    qualifications: array().of(string().required('Sila isi kelayakan')),

    // K - Pengakuan Pemohon
    isBankrupt: string().required('Sila jawab soalan ini'),
    insolvencyStatusCert: mixed().when('isBankrupt', {
      is: 'Tidak',
      then: mixed().required('Sila muat naik Sijil Status Insolvensi'),
    }),
    isCriminal: string().required('Sila jawab soalan ini'),
    isDrugAddict: string().required('Sila jawab soalan ini'),
    isHealthy: string().required('Sila jawab soalan ini')
  })
);

export const tncs = [
  'malaysian',
  'pkrMember',
  'academic',
  'notBankrupt',
  'notCriminal',
  'healthy',
  'notDrugAddict',
  'noDebt',
  'extraCondition'
];

export const states = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Pulau Pinang',
  'Perak',
  'Perlis',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Wilayah Persekutuan'
  // 'W.P. Kuala Lumpur',
  // 'W.P. Labuan',
  // 'W.P. Putrajaya'
];

export const calculateAge = (dob) => {
  const date = parse(dob, 'dd/MM/yyyy', new Date());
  const age = differenceInYears(new Date(), date);
  return age;
}

const ROOT_S3 = 'https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/calonkeadilan/';

export const URLS = {
  BACKGROUND_IMAGE: `${ROOT_S3}webp/bg.webp`,
  BANNER: `${ROOT_S3}webp/bnr.webp`,
  LOGO: `${ROOT_S3}png/logo.png`,
  PKR: `${ROOT_S3}svg/pkr.svg`,
  ASSET_DEC: `${ROOT_S3}pdf/asset_declaration/`,
  CALON_BANNER: `${ROOT_S3}webp/calon_banner/`,
  CALON_MUGSHOT: `${ROOT_S3}png/calon_mugshot/`,
};

export const getCandidateBannerFile = (state, code) => {
  if(state === 'placeholder') return `${URLS.CALON_BANNER}placeholder.webp`;
  else if(state === 'default') return `${URLS.CALON_BANNER}default.webp`;
  return `${URLS.CALON_BANNER}${state?.toLowerCase().split(' ').join('_')}/${code}.webp`;
}

export const getCandidateMugshotFile = (state, code) => {
  if(state === 'placeholder') return `${URLS.CALON_MUGSHOT}placeholder.png`;
  else if(state === 'default') return `${URLS.CALON_MUGSHOT}default.png`;
  return `${URLS.CALON_MUGSHOT}${state?.toLowerCase().split(' ').join('_')}/${code}.png`;
}

export const getCandidateAssetDeclarationFile = (state, code) => {
  return `${URLS.ASSET_DEC}${state?.toLowerCase().split(' ').join('_')}/${code}.pdf`;
}

export const capitalizeString = (str='', onlyFirstWord=false) => {
  if(onlyFirstWord) {
      return str[0].toUpperCase() + str.substring(1);
  }
  else {
      let split = str.split(' ');
      return split.map((w) => w[0].toUpperCase() + w.substring(1)).join(' ');
  }
}