import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { getCandidatesByNegeri } from 'api';
import Helmet from 'components/Helmet';
import TextField from 'components/TextField';
import { states, URLS } from 'helper';
import useLoading from 'hooks/useLoading';
import useStore from 'hooks/useStore';
import useTranslator from 'hooks/useTranslator';
import { useEffect } from 'react';
import Candidates from './Candidates';

const styles = {
  container: (theme) => ({
    width: '100vw',
    height: '100vh',
    minHeight: 740,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: `transparent url(${URLS.BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '100vh',
      py: 8
    }
  }),
  pkrLogo: {
    width: 128,
    height: 64
  },
  masthead: (theme) => ({
    my: '18px',
    lineHeight: '115px',
    [theme.breakpoints.down('sm')]: {
      px: '10vw',
      lineHeight: '76px',
      fontSize: 60
    }
  }),
  mastheadDesc: (theme) => ({
    width: '100%',
    px: '28vw',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  }),
  dropdownContainer: (theme) => ({
    width: '100%',
    my: 3,
    px: 5,
    [theme.breakpoints.down('sm')]: {
      px: 0
    }
  }),
  container2: (theme) => ({
    pt: '107px',
    pb: '122px',
    px: '29vw',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  }),
  title: (theme) => ({
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28
    }
  }),
  footer: (theme) => ({
    backgroundColor: 'primary.main',
    height: 540,
    width: '100%',
    py: '74px',
    px: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  }),
  pkrLogoSmall: {
    width: 86,
    height: 43,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.3)',
  }
}

const Home = () => {
  const { t } = useTranslator();

  const { state, setState, parlimenCandidates, dunCandidates, setParlimenCandidates, setDunCandidates } = useStore();

  const { loading, setLoading } = useLoading();

  const fetchCandidates = async (state) => {
    setLoading(true);
    const res = await getCandidatesByNegeri(state.toLowerCase());
    if(res.status === 200 && res.data && Array.isArray(res.data)) {
      let parlimens = [], duns = [];
      res.data.forEach(e => {
        if(e.parlimen) parlimens.push(e);
        if(e.dun) duns.push(e);
      });
      setParlimenCandidates(parlimens.sort((a,b) => a.kod_parlimen.localeCompare(b.kod_parlimen)));
      setDunCandidates(duns.sort((a,b) => a.kod_dun.localeCompare(b.kod_dun)));
    }
    setLoading(false);
  }

  useEffect(() => {
    if(state) {
      setParlimenCandidates([]);
      setDunCandidates([]);
      fetchCandidates(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if((parlimenCandidates.length || dunCandidates.length) && !loading) {
      document.getElementById('list-calon').scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [parlimenCandidates, dunCandidates, loading]);

  return (
    <Grid container>
      <Helmet/>
      <Grid item xs={12} sx={styles.container}>
        <Box component={'img'} src={URLS.PKR} sx={styles.pkrLogo}/>
        <Typography variant={'masthead'} align={'center'} sx={styles.masthead}>
          {t('t:masthead')}
        </Typography>
        <Box sx={styles.mastheadDesc}>
          <Typography variant={'body1'} align={'center'}>
            {t('t:mastheadCalon')}
          </Typography>
          <Grid container sx={styles.dropdownContainer}>
            <Grid item xs={12}>
              <TextField
                name={'state'}
                value={state || t('t:state')}
                onChange={(e) => setState(e.target.value)}
                fullWidth
                select
                sx={{backgroundColor: 'lighterGrey'}}
              >
                <MenuItem value={t('t:state')} disabled>
                  <Typography variant={'body2'} color={'darkGrey'}>{t('t:state')}</Typography>
                </MenuItem>
                {states.map((s,i) => (
                  <MenuItem value={s} key={i}>
                    <Typography variant={'body2'} color={'black'}>{s}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      { state && (parlimenCandidates.length || dunCandidates.length) ?
      <Grid container id={'list-calon'}>
        <Grid item xs={12}>
          <Candidates/>
        </Grid>
      </Grid> : null }

      <Grid item xs={12} sx={styles.footer}>
        <Box component={'img'} src={URLS.PKR} sx={styles.pkrLogoSmall}/>
        <Box>
          <Typography variant={'footerText'} fontFamily={'AGE'} lineHeight={1} sx={styles.title}>PARTI</Typography><br/>
          <Typography variant={'footerText'} lineHeight={1} sx={styles.title}>KEADILAN</Typography><br/>
          <Typography variant={'footerText'} fontFamily={'AGE'} lineHeight={1} sx={styles.title}>RAKYAT</Typography>
          <Typography variant={'caption'}><br/><br/>PAKATAN HARAPAN</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Home;