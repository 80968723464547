import { Box, Grid, Typography } from '@mui/material';
import useTranslator from 'hooks/useTranslator';
import { getCandidateMugshotFile } from 'helper';
import { useNavigate } from 'react-router-dom';
import useStore from 'hooks/useStore';
import ProgressiveImage from 'components/ProgressiveImage';

const styles = {
  container: (theme) => ({
    backgroundColor: 'white',
    px: '21vw',
    py: 7,
    [theme.breakpoints.down('sm')]: {
      px: '10vw'
    }
  }),
  header: {
    borderBottom: 1,
    borderColor: 'darkerGrey',
    py: 2
  },
  stateName: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  }),
  calonList: (theme) => ({
    py: 3
  }),
  calonCard: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  mugshotImg: (theme) => ({
    width: '100%'
  })
};

const Candidates = () => {
  const { t } = useTranslator();
	const navigate = useNavigate();

  const { state, setSelectedCandidate, parlimenCandidates, dunCandidates } = useStore();

  const selectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
    if(candidate.parlimen) navigate(`candidate/${candidate.kod_parlimen}`);
    else if(candidate.dun) navigate(`candidate/${candidate.negeri?.toLowerCase().split(' ').join('_')}/${candidate.kod_dun}`);
  }

  return (
    <Grid container sx={styles.container}>
      { parlimenCandidates.length ?
      <Grid container item xs={12}>
        <Grid container item xs={12} alignItems={'flex-end'} sx={styles.header}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'footerText'} lineHeight={1} color={'primary'} textTransform={'uppercase'}>
              {t('t:parlimen')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={styles.stateName}>
            <Typography variant={'button'} lineHeight={1} color={'primary'} textTransform={'uppercase'}>
              {state}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={styles.calonList}>
          {parlimenCandidates.map((c,i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Box sx={styles.calonCard} onClick={() => selectCandidate(c)}>
                <ProgressiveImage
                  src={getCandidateMugshotFile(c.negeri, c.kod_parlimen)}
                  placeholderSrc={getCandidateMugshotFile('placeholder')}
                  errorSrc={getCandidateMugshotFile('default')}
                  sx={styles.mugshotImg}
                />
                <Box sx={{py: 1, textAlign: 'center'}}>
                  <Typography color={'black'} textTransform={'uppercase'} variant={'subtitle'}>{c.kod_parlimen}</Typography><br/>
                  <Typography color={'black'} textTransform={'uppercase'} variant={'subtitle'}>{c.parlimen}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid> : null }
      
      { dunCandidates.length ?
      <Grid container item xs={12} sx={{pt: 8}}>
        <Grid container item xs={12} alignItems={'flex-end'} sx={styles.header}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'footerText'} lineHeight={1} color={'primary'} textTransform={'uppercase'}>
              {t('t:dunShort')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={styles.stateName}>
            <Typography variant={'button'} lineHeight={1} color={'primary'} textTransform={'uppercase'}>
              {state}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} sx={styles.calonList}>
          {dunCandidates.map((c,i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Box sx={styles.calonCard} onClick={() => selectCandidate(c)}>
                <ProgressiveImage
                  src={getCandidateMugshotFile(c.negeri, c.kod_dun)}
                  placeholderSrc={getCandidateMugshotFile('placeholder')}
                  errorSrc={getCandidateMugshotFile('default')}
                  sx={styles.mugshotImg}
                />
                <Box sx={{py: 1, textAlign: 'center'}}>
                  <Typography color={'black'} textTransform={'uppercase'} variant={'subtitle'}>{c.kod_dun}</Typography><br/>
                  <Typography color={'black'} textTransform={'uppercase'} variant={'subtitle'}>{c.dun}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid> : null }
    </Grid>
  );
}

export default Candidates;