import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ml from 'translations/ml';
import en from 'translations/en';

i18n.use(initReactI18next).init({
  fallbackLng: 'ml',
  debug: false,
  resources: {
    ml,
    en
  },
  react: {
    useSuspense: false
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;