import { TextField as MuiTextField } from '@mui/material';

const styles = {
  field: {
  },
  label: {
    display: 'none'
  },
  input: {
    py: '11px',
    px: '20px',
    backgroundColor: 'lighterGrey',
    border: 'none',
    fontSize: 21,
    lineHeight: '41px'
  }
};

const TextField = ({ 
  InputLabelProps,
  inputProps,
  ...rest
}) => {
  return (
    <MuiTextField
      variant={'standard'}
      sx={styles.field}
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        sx: styles.label
      }}
      inputProps={{
        ...inputProps,
        sx: styles.input
      }}
      InputProps={{
        disableUnderline: true
      }}
      FormHelperTextProps={{
        sx: {
          '&.Mui-error': {
            color: 'red'
          }
        }
      }}
      {...rest}
    />
  );
}

export default TextField;